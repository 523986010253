import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageWrapperContext from "../context/PageWrapperContext"
import DatoCmsText from "../components/DatoCmsText/DatoCmsText"
import ArticleLayout from "../layouts/ArticleLayout"
import CardCarouselSection from "../sections/generic/CardCarousel"

export default ({ data: { job, labels } }) => {
  const { setTheme, setHeaderCollapsedTheme, setHeaderTheme } = useContext(
    PageWrapperContext
  )
  useEffect(() => {
    setTheme(PageWrapperContext.default.theme)
    setHeaderCollapsedTheme(null)
    setHeaderTheme(PageWrapperContext.default.theme)
  })
  return (
    <ArticleLayout
      locale={job.locale}
      title={job.title}
      author={job.requester}
      writtenByLabel={labels.jobRequesterLabel}
      contact={{
        ...job.requester,
        title: labels.apply,
        linkLabel: labels.contact,
        pleaseContactLabel: labels.sendYourCvTo,
      }}
      content={[{ node: <DatoCmsText text={job.jobDescription} /> }]}
    >
      <HelmetDatoCms seo={job.seoMetaTags} />
      <CardCarouselSection
        title={labels.relatedVacanciesTitle}
        description={labels.relatedVacanciesDescription}
        cards={job.related}
        style={{ marginTop: "150px" }}
      />
    </ArticleLayout>
  )
}

export const query = graphql`
  query($id: String!, $locale: String!) {
    labels: datoCmsTranslation(locale: { eq: $locale }) {
      contact
      jobRequesterLabel
      apply
      sendYourCvTo
      relatedVacanciesTitle
      relatedVacanciesDescription
    }
    job: datoCmsJobOffer(id: { eq: $id }, locale: { eq: $locale }) {
      locale
      title
      jobDescription
      requester {
        email
        name
        role
        isAvatarRound
        image {
          ...SmallAvatar
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      related {
        ...JobOfferCard
      }
    }
  }
`
